import { AdjustmentType } from "@/components/batch/adjust/models/batch-adjust.model";
import {
  Audit,
  AuditStatus,
  ItemAudit,
  ProductInfoAudit
} from "@/interfaces/audit";
import { i18n } from "@/plugins/i18n";
import { getParentSKU } from "@/utils/batch-actions.utils";
import {
  FormField,
  FormFieldTypes,
  FormModel,
  TableFieldType,
  TableHeader
} from "helix-vue-components";

export const AuditMetadata: TableHeader[] = [
  {
    value: "operation_uid",
    label: String(i18n.t("id")),
    fieldComponent: TableFieldType.string,
    conditionalClassFn: (field: Audit) =>
      field.real_time_audit ? "real-time" : "no-real-time"
  },
  {
    value: "rooms_table",
    label: String(i18n.t("rooms.view_title")),
    fieldComponent: TableFieldType.string,
    conditionalClassFn: () => "inner-pop-room"
  },
  {
    value: "product_categories_table",
    label: String(i18n.t("product_categories")),
    fieldComponent: TableFieldType.string,
    sortable: true,
    conditionalClassFn: () => "inner-pop-categories"
  },
  {
    value: "created_at",
    label: String(i18n.t("created")),
    fieldComponent: TableFieldType.fnsDate
  },

  {
    value: "status",
    label: String(i18n.t("audit.status")),
    fieldComponent: TableFieldType.enumText,
    conditionalClassFn: arg => {
      return [AuditStatus.ERROR, AuditStatus.TRC_CONNECTION_ERROR].includes(
        arg.status
      )
        ? "inner-pop-error"
        : "";
    },
    enum: {
      OPENED: i18n.t("audit.opened").toString(),
      AUDITED: i18n.t("audit.audited").toString(),
      CLOSED: i18n.t("audit.closed").toString(),
      CANCELED: i18n.t("audit.cancelled").toString(),
      EXPIRED: i18n.t("audit.expired").toString(),
      BUILDING: i18n.t("audit.building").toString(),
      PAUSED: i18n.t("audit.paused").toString(),
      CANCELING: i18n.t("audit.cancelling").toString(),
      ERROR: i18n.t("audit.error").toString(),
      TRC_CONNECTION_ERROR: i18n.t("audit.traceability_system_error").toString()
    },
    class: "tdt__headers__fieldLong"
  }
];

export const productAudit: TableHeader[] = [
  {
    value: "product_name",
    label: i18n.t("audit.product").toString(),
    fieldComponent: TableFieldType.string,
    sortable: true,
    conditionalClassFn: () => "font-weight-bold"
  },
  {
    value: "product_category",
    label: i18n.t("audit.product_category").toString(),
    fieldComponent: TableFieldType.string,
    sortable: true
  },
  {
    value: "total_batches",
    label: i18n.t("audit.batch_count").toString(),
    fieldComponent: TableFieldType.string,
    sortable: true
  },
  {
    value: "total_old_quantity",
    label: i18n.t("audit.original_quantity").toString(),
    fieldComponent: TableFieldType.string,
    sortable: true
  },
  {
    value: "available_reserved",
    label: i18n.t("audit.available_reserved").toString(),
    fieldComponent: TableFieldType.string,
    sortable: true
  },
  {
    value: "total_new_quantity",
    label: i18n.t("audit.new_quantity").toString(),
    fieldComponent: TableFieldType.string,
    sortable: true
  },
  {
    value: "difference",
    label: i18n.t("audit.difference").toString(),
    fieldComponent: TableFieldType.string,
    sortable: true,
    conditionalClassFn: (field: any) => {
      return +String(field.difference).slice(0, -1) < 0
        ? "negative"
        : +String(field.difference).slice(0, -1) > 0
        ? "positive"
        : "";
    }
  },
  {
    value: "status",
    label: i18n.t("status").toString(),
    fieldComponent: TableFieldType.enumText,
    sortable: true,
    enum: {
      AUDITED: "audit.audited",
      PENDING: "audit.pending",
      RECOUNT: "audit.recount",
      CANCELED: "audit.canceled",
      COUNTED: "audit.counted",
      NOT_AVAILABLE: "audit.not_available",
      ERROR: "audit.traceability_system_error"
    },
    class: "tdt__headers__fieldLong",
    conditionalClassFn: (field: any) => {
      return field.status.toLowerCase();
    }
  }
];

export const productBatchAudit: TableHeader[] = [
  {
    value: "batch_uid",
    label: i18n.t("batch").toString(),
    fieldComponent: TableFieldType.string,
    sortable: true
  },
  {
    value: "room_name",
    label: i18n.t("audit.room").toString(),
    fieldComponent: TableFieldType.string,
    sortable: true
  },
  {
    value: "tracking_id",
    label: i18n.t("audit.secondary_id").toString(),
    fieldComponent: TableFieldType.string,
    sortable: true
  },
  {
    value: "total_old_quantity",
    label: i18n.t("audit.original_quantity").toString(),
    fieldComponent: TableFieldType.string,
    sortable: true
  },
  {
    value: "available_reserved",
    label: i18n.t("audit.available_reserved").toString(),
    fieldComponent: TableFieldType.string,
    sortable: true
  },
  {
    value: "total_new_quantity",
    label: i18n.t("audit.new_quantity").toString(),
    fieldComponent: TableFieldType.string,
    sortable: true
  },
  {
    value: "difference",
    label: i18n.t("audit.difference").toString(),
    fieldComponent: TableFieldType.string,
    sortable: true,
    conditionalClassFn: (field: any) => {
      return +String(field.difference).slice(0, -1) < 0
        ? "negative"
        : +String(field.difference).slice(0, -1) > 0
        ? "positive"
        : "";
    }
  },
  {
    value: "status",
    label: i18n.t("status").toString(),
    fieldComponent: TableFieldType.enumText,
    sortable: true,
    enum: {
      AUDITED: "audit.audited",
      PENDING: "audit.pending",
      RECOUNT: "audit.recount",
      CANCELED: "audit.canceled",
      COUNTED: "audit.counted",
      NOT_AVAILABLE: "audit.not_available",
      ERROR: "audit.traceability_system_error"
    },
    class: "tdt__headers__fieldLong",
    conditionalClassFn: (field: any) => {
      return field.status.toLowerCase();
    }
  }
];

export const batchAudit: TableHeader[] = [
  {
    value: "batch_uid",
    label: i18n.t("batch").toString(),
    fieldComponent: TableFieldType.string,
    sortable: true
  },
  {
    value: "tracking_id",
    label: i18n.t("audit.secondary_id").toString(),
    fieldComponent: TableFieldType.string,
    sortable: true
  },
  {
    value: "product_name",
    label: i18n.t("audit.product").toString(),
    fieldComponent: TableFieldType.string,
    sortable: true
  },
  {
    value: "room_name",
    label: i18n.t("audit.room").toString(),
    fieldComponent: TableFieldType.string,
    sortable: true
  },
  {
    value: "old_quantity",
    label: i18n.t("audit.original_quantity").toString(),
    fieldComponent: TableFieldType.string,
    sortable: true
  },
  {
    value: "available_reserved",
    label: i18n.t("audit.available_reserved").toString(),
    fieldComponent: TableFieldType.string,
    sortable: true
  },
  {
    value: "new_quantity",
    label: i18n.t("audit.new_quantity").toString(),
    fieldComponent: TableFieldType.string,
    sortable: true
  },
  {
    value: "difference",
    label: i18n.t("audit.difference").toString(),
    fieldComponent: TableFieldType.string,
    sortable: true,
    conditionalClassFn: (field: any) => {
      return +String(field.difference).slice(0, -1) < 0
        ? "negative"
        : +String(field.difference).slice(0, -1) > 0
        ? "positive"
        : "";
    }
  },
  {
    value: "status",
    label: i18n.t("status").toString(),
    fieldComponent: TableFieldType.enumText,
    sortable: true,
    enum: {
      AUDITED: "audit.audited",
      PENDING: "audit.pending",
      RECOUNT: "audit.recount",
      CANCELED: "audit.canceled",
      COUNTED: "audit.counted",
      NOT_AVAILABLE: "audit.not_available",
      ERROR: "audit.traceability_system_error"
    },
    class: "tdt__headers__fieldLong",
    conditionalClassFn: (field: any) => {
      return field.status.toLowerCase();
    }
  }
];

export const roomAudit: TableHeader[] = [
  {
    value: "inventory_location_id",
    label: "room",
    fieldComponent: TableFieldType.string,
    sortable: true
  },
  {
    value: "old_quantity/j/quantity_unit",
    label: "original",
    fieldComponent: TableFieldType.string,
    sortable: true
  },
  {
    value: "new_quantity/j/quantity_unit",
    label: "new",
    fieldComponent: TableFieldType.string,
    sortable: true
  },
  {
    value: "difference/j/quantity_unit",
    label: "difference",
    fieldComponent: TableFieldType.string,
    sortable: true
  },
  {
    value: "status",
    label: "status",
    fieldComponent: TableFieldType.string,
    sortable: true
  }
];

export const configCurrentBatchForm = (
  update: () => void,
  readonly: () => boolean,
  hasMetrc: boolean,
  hasError: () => boolean,
  metrcAdjustmentReasons: AdjustmentType[],
  bioTrackTraceEnabled: boolean,
  bioTrackAdjustmentReasons: AdjustmentType[],
  hasModifyPermission: boolean
): FormField[] => {
  const items: FormField[] = [
    {
      label: i18n.t("audit.secondary_id"),
      name: "options.tracking_id",
      component: FormFieldTypes.textField,
      options: {
        disableCondition: item =>
          !hasModifyPermission ||
          (readonly() ||
            (+item.new_quantity === +item.old_quantity && !item.tracking_id)),
        visibleCondition: item =>
          !bioTrackTraceEnabled &&
          (hasError() || (hasMetrc && item.has_cannabis && !item.tracking_id)),
        contextRules: (arg: FormModel) => {
          if (+arg.new_quantity !== +arg.old_quantity) {
            return ["required"];
          }
          return [""];
        },
        row: 1,
        flex: 6
      }
    },
    {
      label: i18n.t("audit.new_count"),
      name: "new_quantity",
      component: FormFieldTypes.numericField,
      options: {
        disableCondition: () => !hasModifyPermission || readonly(),
        validationRules: ["required"],
        row: 2,
        flex: 6
      }
    },
    {
      label: i18n.t("audit.biotrack_adjustament_reason"),
      name: "batch_adjustment_type_id",
      component: FormFieldTypes.autocomplete,
      options: {
        visibleCondition: () => bioTrackTraceEnabled,
        disableCondition: () => !hasModifyPermission || readonly(),
        placeholder: "General Inventory Audit",
        associationText: "name",
        associationValue: "id",
        validationRules: ["required"],
        selectOptions: () => bioTrackAdjustmentReasons,
        row: 3,
        flex: 12
      }
    },
    {
      label: i18n.t("audit.metric_weighable"),
      name: "options.metrc_weighable",
      component: FormFieldTypes.checkbox,
      options: {
        disableCondition: () => !hasModifyPermission || readonly(),
        visibleCondition: () => !bioTrackTraceEnabled && hasError(),
        row: 4,
        flex: 6
      }
    },
    {
      label: i18n.t("audit.metrc_tag_status"),
      name: "options.metrc_unfinish_tag",
      component: FormFieldTypes.select,
      options: {
        visibleCondition: () => !bioTrackTraceEnabled && hasError(),
        disableCondition: () => !hasModifyPermission || readonly(),
        placeholder: i18n.t("select_type"),
        associationText: "text",
        associationValue: "value",
        selectOptions: () => [
          {
            value: false,
            text: i18n.t("finish").toString()
          },
          {
            value: true,
            text: i18n.t("unfinish").toString()
          }
        ],
        row: 5,
        flex: 6
      }
    },
    {
      label: i18n.t("audit.metrc_adjustament_reason"),
      name: "batch_adjustment_type_id",
      component: FormFieldTypes.autocomplete,
      options: {
        associationText: "name",
        associationValue: "id",
        contextRules: (item: FormModel) => {
          if (+item.new_quantity !== +item.old_quantity && item.has_cannabis) {
            return ["required"];
          }
          return [""];
        },
        visibleCondition: item => hasMetrc && item.has_cannabis,
        disableCondition: item =>
          !hasModifyPermission ||
          (+item.new_quantity === +item.old_quantity ||
            !item.has_cannabis ||
            readonly()),
        selectOptions: () => metrcAdjustmentReasons,
        row: 6,
        flex: 12
      }
    },
    {
      label: i18n.t("note"),
      name: "reason",
      component: FormFieldTypes.textarea,
      options: {
        disableCondition: () => !hasModifyPermission || readonly(),
        validationRules: ["required"],
        row: 7,
        flex: 12
      }
    }
  ];
  if (!readonly()) {
    items.push({
      component: FormFieldTypes.buttonEvent,
      label: i18n.t("update"),
      name: "update_audit",
      id: "btn_update_audit",
      options: {
        click: update,
        disableCondition: () => !hasModifyPermission,
        row: 8,
        flex: 6,
        buttonClass: "mt-2"
      }
    });
  }

  return items;
};

const difference = (item: ItemAudit) => {
  const diff = +item.total_new_quantity - +item.total_old_quantity;
  return +(item.total_audited && diff.toFixed(2)) || "";
};

const statusItem = (item: ItemAudit) => {
  return item.total_canceled
    ? AuditStatus.CANCELED
    : item.total_fractions === item.total_audited
    ? AuditStatus.AUDITED
    : item.total_fractions === item.total_counted
    ? AuditStatus.COUNTED
    : item.total_not_available
    ? AuditStatus.NOT_AVAILABLE
    : item.total_error
    ? AuditStatus.ERROR
    : item.total_recount
    ? AuditStatus.RECOUNT
    : AuditStatus.PENDING;
};

export const mapperProductAudit = (data: {
  data: ItemAudit[];
  products: ProductInfoAudit[];
}) => {
  const differenceQuantity = (item: ItemAudit, unit?: string | number) => {
    const diff = +item.total_new_quantity - +item.total_old_quantity;
    return unit ? `${+diff.toFixed(2)}${unit}` : +diff.toFixed(2);
  };

  return data.data.map(item => {
    const currentInfo = data.products.find(product => product.sku === item.sku);
    return {
      ...item,
      available_reserved: `${+item.total_new_quantity! -
        +item.total_reserved_quantity!}${
        currentInfo ? currentInfo!.unit : ""
      }/${+item.total_reserved_quantity!}${
        currentInfo ? currentInfo!.unit : ""
      }`,
      total_old_quantity: `${item.total_old_quantity}${
        currentInfo ? currentInfo!.unit : ""
      }`,
      total_new_quantity: `${item.total_new_quantity}${
        currentInfo ? currentInfo!.unit : ""
      }`,
      product_info: currentInfo,
      category: item.product_category,
      product_name: currentInfo ? currentInfo!.name : "",
      difference: differenceQuantity(
        item,
        currentInfo ? currentInfo!.unit : ""
      ),
      status: statusItem(item)
    };
  });
};

export const mapperProductBatchAudit = (data: ItemAudit[]) => {
  const differenceQuantities = (item: ItemAudit) => {
    const diff = +item.new_quantity! - +item.old_quantity!;
    return `${+diff.toFixed(2)}${item.quantity_unit}` || "";
  };
  return data.map(item => {
    return {
      ...item,
      available_reserved: `${+item.new_quantity! - +item.reserved_quantity!}${
        item.quantity_unit
      }/${+item.reserved_quantity!}${item.quantity_unit}`,
      old_quantity: `${item.old_quantity!}${item.quantity_unit}`,
      new_quantity: `${item.new_quantity!}${item.quantity_unit}`,
      product_info: item.product_info,
      product_name: item.product_name,
      room_name: item.room_name,
      difference: differenceQuantities(item)
    };
  });
};

export const mapperBatchesAudit = (data: ItemAudit[]) => {
  const differenceQuantities = (item: ItemAudit) => {
    const diff = +item.total_new_quantity! - +item.total_old_quantity!;
    return `${+diff.toFixed(2)}${item.quantity_unit}` || "";
  };
  return data.map(item => {
    return {
      ...item,
      available_reserved: `${+item.total_new_quantity! -
        +item.total_reserved_quantity!}${
        item.quantity_unit
      }/${+item.total_reserved_quantity!}${item.quantity_unit}`,
      total_old_quantity: `${item.total_old_quantity}${item.quantity_unit}`,
      total_new_quantity: `${item.total_new_quantity}${item.quantity_unit}`,
      batchRoomKey: `${item.batch_uid}${item.inventory_location_id}`,
      difference: differenceQuantities(item),
      status: statusItem(item),
      batchUid: item.batch_uid
    };
  });
};
